import React from 'react';

import Reel from '@myungsoo/base/pages/Reel';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as config from '../../config';

const ReelPage = () => {
  const [loading, setLoading] = React.useState(false);
  const [headerBounds, setHeaderBounds] = React.useState({});
  return (
    <Layout loading={loading} onHeaderResize={setHeaderBounds}>
      <Seo title="Reel" />
      <Reel
        config={config}
        headerBounds={headerBounds}
        onLoadingChange={setLoading}
      />
    </Layout>
  );
};

export default ReelPage;
