import React from 'react';
import fp from 'lodash/fp';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Measure from 'react-measure';
import closeIcon from '@fortawesome/fontawesome-free/svgs/solid/times.svg';
import { Link } from '@reach/router';

import { Button, ImageText, Loader } from '@myungsoo/components';
import { VideoThumbnail } from '@myungsoo/base/components';
import { mediaQueries as mq } from '@myungsoo/base/styles';

import VideoPlayer from './VideoPlayer';

const closeButtonSize = 46;

const CloseButton = ({ ...restProps }) => (
  <Button
    css={css`
      box-sizing: border-box;
      width: ${closeButtonSize}px;
      height: ${closeButtonSize}px;
      padding: 0.5rem;

      background: none;
      color: white;
      opacity: 0.75;
      font-size: 1rem;
      outline: none;

      transition: height 0.3s opacity 0.3s;

      :hover {
        opacity: 1;
      }
    `}
    {...restProps}
  >
    <ImageText
      css={css`
        width: 100%;
        height: 100%;
      `}
      src={closeIcon}
    >
      Close
    </ImageText>
  </Button>
);

const Title = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  text-shadow: 0 0 0.25rem #000;
`;

const CatetoryList = ({ categories, ...restProps }) => (
  <ul
    css={css`
      margin: 0;
      padding: 0;
      list-style: none;
    `}
    {...restProps}
  >
    {fp.map((category) => (
      <li
        key={category.id}
        css={css`
          display: inline-block;
          margin-right: 0.25rem;
          padding: 0.15rem 0;

          font-size: 0.7rem;

          background: rgba(0, 0, 0, 0.75);
        `}
      >
        {category.name}
      </li>
    ))(categories)}
  </ul>
);

const Thumbnail = styled(VideoThumbnail)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  transition: opacity 0.3s;
`;

const Article = ({
  article,
  selected,
  scrollTopOffset,
  onClose,
  ...restProps
}) => {
  const { id, title, description, video, categories } = article;

  const [playerLoaded, setPlayerLoaded] = React.useState(false);
  const [offsetTop, setOffsetTop] = React.useState(0);

  const [width, setWidth] = React.useState(0);
  const [headerHeight, setHeaderHeight] = React.useState(0);
  const [descriptionHeight, setDescriptionHeight] = React.useState(0);

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    setTimeout(() => {
      window.scrollTo(0, offsetTop - scrollTopOffset);
    }, 300);
  }, [selected, offsetTop, scrollTopOffset]);

  const videoHeight = 0.56 * width;
  const openedHeight =
    closeButtonSize + videoHeight + headerHeight + descriptionHeight;

  return (
    <Measure
      offset
      bounds
      onResize={(contentRect) => {
        setWidth(contentRect.bounds.width);
        setOffsetTop(contentRect.offset.top);
      }}
    >
      {({ measureRef }) => (
        <article
          ref={measureRef}
          css={[
            css`
              position: relative;
              height: 40vw;
              overflow: hidden;
              cursor: pointer;
              transition: height 0.3s;

              @media ${mq[0]} {
                flex: 1 30%;
                max-width: 33.4%;
                height: 18.6vw;
              }
            `,
            !selected &&
              css`
                :hover ${Thumbnail} {
                  opacity: 1;
                }
              `,
            selected &&
              css`
                height: ${openedHeight}px;
                cursor: inherit;

                @media ${mq[0]} {
                  flex: 1 100%;
                  height: ${openedHeight}px;
                }
              `,
          ]}
          {...restProps}
        >
          <div
            css={css`
              text-align: right;
              height: ${selected ? closeButtonSize : 0}px;
              overflow: hidden;
              transition: height 0.3s;
            `}
          >
            <CloseButton onClick={onClose} />
          </div>

          <div
            css={[
              css`
                position: relative;
                height: 100%;
                transition: height 0.3s;
              `,
              selected &&
                css`
                  height: ${videoHeight}px;
                `,
            ]}
          >
            <Thumbnail
              css={css`
                position: absolute;
                z-index: -1;
              `}
              videoId={video.id}
              videoService={video.service}
            />

            {selected && (
              <VideoPlayer
                css={css`
                  width: 100%;
                  height: ${videoHeight}px;
                `}
                videoId={video.id}
                videoService={video.service}
                height={videoHeight}
                onReady={() => setPlayerLoaded(true)}
              />
            )}

            {selected && !playerLoaded && (
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  pointer-events: none;
                  z-index: 0;
                `}
              >
                <Loader />
              </div>
            )}
          </div>

          <Measure
            bounds
            onResize={(contentRect) => {
              setHeaderHeight(contentRect.bounds.height);
            }}
          >
            {({ measureRef }) => (
              <div
                ref={measureRef}
                css={[
                  css`
                    box-sizing: border-box;
                    margin-top: -${headerHeight}px;
                    padding: 0.5rem;
                    padding-top: 1rem;
                    padding-bottom: 0.5rem;

                    transition: margin 0.3s;
                  `,
                  selected &&
                    css`
                      margin: 0;
                    `,
                ]}
              >
                <Title>{title}</Title>
                <CatetoryList categories={categories} />
              </div>
            )}
          </Measure>

          <Measure
            bounds
            onResize={(contentRect) => {
              setDescriptionHeight(contentRect.bounds.height);
            }}
          >
            {({ measureRef }) => (
              <div
                ref={measureRef}
                css={css`
                  box-sizing: border-box;
                  padding: 0.5rem;
                  padding-top: 0.5rem;
                  padding-bottom: 1rem;
                `}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </Measure>

          {!selected && (
            <Link
              css={css`
                display: 'block';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              `}
              to={`/reel?id=${id}`}
            />
          )}
        </article>
      )}
    </Measure>
  );
};

export default Article;
