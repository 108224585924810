import React from 'react';
import fp from 'lodash/fp';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Measure from 'react-measure';
import closeIcon from '@fortawesome/fontawesome-free/svgs/solid/times.svg';

import { Button, Container, ImageText, Loader } from '@myungsoo/components';

import VideoPlayer from './VideoPlayer';

const closeButtonSize = 46;

const CloseButton = ({ ...restProps }) => (
  <Button
    css={css`
      box-sizing: border-box;
      width: ${closeButtonSize}px;
      height: ${closeButtonSize}px;
      padding: 0.5rem;

      background: none;
      color: white;
      opacity: 0.75;
      font-size: 1rem;
      outline: none;

      transition: height 0.3s opacity 0.3s;

      :hover {
        opacity: 1;
      }
    `}
    {...restProps}
  >
    <ImageText
      css={css`
        width: 100%;
        height: 100%;
      `}
      src={closeIcon}
    >
      Close
    </ImageText>
  </Button>
);

const Title = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  text-shadow: 0 0 0.25rem #000;
`;

const CatetoryList = ({ categories, ...restProps }) => (
  <ul
    css={css`
      margin: 0;
      padding: 0;
      list-style: none;
    `}
    {...restProps}
  >
    {fp.map((category) => (
      <li
        key={category.id}
        css={css`
          display: inline-block;
          margin-right: 0.25rem;
          padding: 0.15rem 0;

          font-size: 0.7rem;

          background: rgba(0, 0, 0, 0.75);
        `}
      >
        {category.name}
      </li>
    ))(categories)}
  </ul>
);

const Article = ({
  article: articleProp,
  opened,
  scrollTopOffset,
  onClose,
  ...restProps
}) => {
  const [article, setArticle] = React.useState(articleProp);
  const [playerLoaded, setPlayerLoaded] = React.useState(false);
  const [offsetTop, setOffsetTop] = React.useState(0);

  const [videoWidth, setVideoWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (!articleProp) {
      return;
    }
    setArticle(articleProp);
  }, [articleProp]);

  React.useEffect(() => {
    if (!opened) {
      return;
    }

    setTimeout(() => {
      window.scrollTo(
        0,
        offsetTop - (scrollTopOffset + 0.05 * window.innerWidth),
      );
    }, 300);
  }, [opened, article, offsetTop, scrollTopOffset]);

  const videoHeight = 0.56 * videoWidth;

  const { title, description, video = {}, categories } = article || {};

  return (
    <Measure
      offset
      onResize={(contentRect) => {
        setOffsetTop(contentRect.offset.top);
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          css={[
            css`
              flex: 1 80%;
              height: 0;
              overflow: hidden;
              transition: height 0.3s;
            `,
            opened &&
              css`
                height: ${height}px;
              `,
          ]}
          {...restProps}
        >
          <div
            css={css`
              text-align: right;
            `}
          >
            <CloseButton onClick={onClose} />
          </div>

          <Measure
            bounds
            onResize={(contentRect) => {
              setHeight(contentRect.bounds.height + closeButtonSize);
            }}
          >
            {({ measureRef }) => (
              <Container
                ref={measureRef}
                css={css`
                  max-width: 110vh;
                `}
              >
                <Measure
                  bounds
                  onResize={(contentRect) => {
                    setVideoWidth(contentRect.bounds.width);
                  }}
                >
                  {({ measureRef }) => (
                    <div
                      ref={measureRef}
                      css={[
                        css`
                          position: relative;
                          height: ${videoHeight}px;
                        `,
                      ]}
                    >
                      {opened && (
                        <VideoPlayer
                          css={css`
                            width: ${videoWidth}px;
                            height: ${videoHeight}px;
                          `}
                          videoId={video.id}
                          videoService={video.service}
                          width={videoWidth}
                          height={videoHeight}
                          onReady={() => setPlayerLoaded(true)}
                        />
                      )}

                      {opened && !playerLoaded && (
                        <div
                          css={css`
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            pointer-events: none;
                          `}
                        >
                          <Loader />
                        </div>
                      )}
                    </div>
                  )}
                </Measure>

                <div
                  css={[
                    css`
                      box-sizing: border-box;
                      padding-top: 1rem;
                      padding-bottom: 0.5rem;
                    `,
                  ]}
                >
                  <Title>{title}</Title>
                  <CatetoryList categories={categories} />
                </div>

                <div
                  css={css`
                    box-sizing: border-box;
                    padding-top: 0.5rem;
                    padding-bottom: 1rem;
                  `}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Container>
            )}
          </Measure>
        </div>
      )}
    </Measure>
  );
};

export default Article;
