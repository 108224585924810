import React from 'react';
import { css } from '@emotion/core';

const Item = ({ checked, ...restProps }) => (
  <li>
    <button
      css={[
        css`
          display: inline-block;
          margin: 0;
          padding: 0 0.5rem;
          height: 100%;

          color: white;
          background: none;
          border: none;

          font-size: 0.9rem;

          cursor: pointer;
          outline: none;

          :hover {
            color: black;
            background: white;
          }
        `,
        checked &&
          css`
            color: black;
            background: white;
          `,
      ]}
      {...restProps}
    />
  </li>
);

const CategoryList = ({ ...restProps }) => (
  <ul
    css={css`
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: stretch;
      margin: 0;
      padding: 0;
      list-style: none;

      background: rgba(0, 0, 0, 0.8);
    `}
    {...restProps}
  />
);

CategoryList.Item = Item;

export default CategoryList;
