import React from 'react';
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';

const VideoPlayer = ({
  videoId,
  videoService,
  useIframe = true,
  onReady = () => {},
  ...restProps
}) => {
  switch (videoService) {
    case 'youtube':
      return useIframe ? (
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&playsinline=1&showinfo=0&modestbranding=0`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={videoId}
          onLoad={onReady}
          {...restProps}
        />
      ) : (
        <YouTube
          videoId={videoId}
          opts={{
            playerVars: {
              autoplay: 1,
              playsinline: 1,
              showinfo: 0,
              modestbranding: 0,
              origin: window.location.origin,
            },
          }}
          onReady={onReady}
          {...restProps}
        />
      );
    case 'vimeo':
      return useIframe ? (
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?autoplay=1&app_id=122963`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title={videoId}
          onLoad={onReady}
          {...restProps}
        />
      ) : (
        <Vimeo video={videoId} autoplay onReady={onReady} {...restProps} />
      );
    default:
      return null;
  }
};

export default VideoPlayer;
