import React from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { css } from '@emotion/core';
import getVideoId from 'get-video-id';
import { globalHistory, navigate } from '@reach/router';
import queryString from 'query-string';

import { mediaQueries as mq } from '@myungsoo/base/styles';

import CategoryList from './CategoryList';
import Article from './Article';
import LargeArticle from './LargeArticle';

const Reel = ({
  config: { DB_ROOT_PATH },
  headerBounds = {},
  onLoadingChange = () => {},
}) => {
  const [categories, setCategories] = React.useState([]);
  const [articles, setArticles] = React.useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState('all');
  const [selectedArticleIndex, setSelectedArticleIndex] = React.useState(-1);
  const [largeScreen, setLargeScreen] = React.useState(false);

  const selectedArticle = articles[selectedArticleIndex];

  const loadArticles = React.useCallback(async () => {
    onLoadingChange(true);
    try {
      const firebase = await import('firebase/app');
      const db = firebase.firestore();
      const pageRef = db.doc(`${DB_ROOT_PATH}/pages/reel`);
      const categoriesRef = pageRef.collection('categories');
      const articlesRef = pageRef.collection('articles');

      const [categoryCollection, articleCollection] = await Promise.all([
        categoriesRef.orderBy('createdAt', 'desc').get(),
        articlesRef.orderBy('createdAt', 'desc').get(),
      ]);

      const loadedCategories = fp.flow(
        fp.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
        fp.orderBy('displayOrder', 'asc'),
        (docs) => [{ id: 'all', name: 'All' }, ...docs],
      )(categoryCollection.docs);

      setCategories(loadedCategories);

      const loadedArticles = fp.flow(
        fp.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
        fp.map((article) => ({
          ...article,
          video: getVideoId(article.videoUrl),
          categories: fp.flow(
            fp.map((categoryId) => ({ id: categoryId })),
            fp.intersectionBy('id')(loadedCategories),
          )(article.categoryIds),
        })),
        fp.orderBy('displayOrder', 'asc'),
      )(articleCollection.docs);

      setArticles(loadedArticles);
    } finally {
      onLoadingChange(false);
    }
  }, [DB_ROOT_PATH, onLoadingChange]);

  React.useEffect(() => {
    loadArticles();
  }, [loadArticles]);

  React.useEffect(() => {
    const mql = window.matchMedia(mq[0]);
    setLargeScreen(mql.matches);
    function onMediaQueryChange(e) {
      setLargeScreen(e.matches);
    }
    mql.addListener(onMediaQueryChange);
    return () => mql.removeListener(onMediaQueryChange);
  }, []);

  React.useEffect(() => {
    function updateSelectedArticle({ location }) {
      const { id } = queryString.parse(location.search);
      const articleIndex = _.findIndex(articles, { id });
      setSelectedArticleIndex(articleIndex);
    }

    updateSelectedArticle({ location: window.location });

    return globalHistory.listen(updateSelectedArticle);
  }, [articles]);

  const categoryListHeight = _.size(categories) > 1 ? 36 : 0;

  return (
    <>
      <CategoryList
        css={css`
          position: fixed;
          top: ${headerBounds.height}px;
          left: 0;
          right: 0;
          height: ${categoryListHeight}px;
          overflow: hidden;
          z-index: 1;

          @media ${mq[0]} {
            justify-content: center;
          }
        `}
      >
        {fp.map((category) => (
          <CategoryList.Item
            key={category.id}
            checked={category.id === selectedCategoryId}
            onClick={() => {
              navigate('/reel');
              setSelectedCategoryId(category.id);
            }}
          >
            {category.name}
          </CategoryList.Item>
        ))(categories)}
      </CategoryList>

      <div
        css={css`
          padding-top: ${categoryListHeight}px;

          @media ${mq[0]} {
            display: flex;
            flex-flow: row wrap;
          }
        `}
      >
        {_.map(
          _.filter(
            articles,
            (article) =>
              selectedCategoryId === 'all' ||
              fp.contains(selectedCategoryId)(article.categoryIds),
          ),
          (article, index, filteredArticles) => {
            const selected = index === selectedArticleIndex;
            const scrollTopOffset = headerBounds.height + categoryListHeight;

            return (
              <React.Fragment key={article.id}>
                <Article
                  article={article}
                  selected={!largeScreen && selected}
                  scrollTopOffset={scrollTopOffset}
                  onClose={() => navigate('/reel')}
                />
                {largeScreen &&
                  (index % 3 === 2 ||
                    index === _.size(filteredArticles) - 1) && (
                    <LargeArticle
                      article={selectedArticle}
                      opened={
                        Math.floor(index / 3) ===
                        Math.floor(selectedArticleIndex / 3)
                      }
                      scrollTopOffset={scrollTopOffset}
                      onClose={() => navigate('/reel')}
                    />
                  )}
              </React.Fragment>
            );
          },
        )}
      </div>
    </>
  );
};

export default Reel;
